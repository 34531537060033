<template>
		<section class="section section-shaped my-4 overflow-hidden" id="services">
			<div class="shape shape-style-1 bg-gradient-lighter shape-skew"></div>
			<div class="container">
				<div class="row row-grid">
					<div class="col-md-7">
						<div class="pl-md-5">
							<h2>HealthView Services</h2>
							<h3>Skilled Nurses</h3>
							<p>HealthView skilled nurses are licensed professionals and experts in a variety of health care services. Those services can include care need assessments, evaluation and management of home health plans of care, complex wound care and treatments, and rehabilitation.</p>
							<p>Skilled nurses are also available for injections, IV infusions, and routine lab services such as blood draws, checking medication blood levels, and monitoring chemotherapy drugs</p>

							<h3>Medical Social Worker</h3>
							<p>A medical social worker is available to assist with emotional support and counseling. They can also help you identify resources that can ease the burden an illness can bring. Those can include helping set up a medical power of attorney or DNR order or finding people in the community who can provide maid, lawn, or other household services.</p>

							<h3>Home Health Aides</h3>
							<p>A home health aide may provide assistance under the direction of a skilled clinician, which can include bathing, dressing, and grooming. Our agency does not provide personal care services at this time.</p>
							<div class="text-center">
								<router-link to="/contact" class="btn btn-success mt-3">Contact us to learn more. <icon name="fas fa-envelope"></icon>
								</router-link>
							</div>
						</div>
					</div>
					<div class="col-xs-12 col-md-5">
						<div class="card bg-success shadow border-0">
							<!-- <img v-lazy="'img/theme/listening_to_heartbeat.jpg'" class="card-img-top" alt="Home health services checking heartbeat" title="Checking heartbeat - HealthView"> -->
							<picture class="card-img-top">
                <source srcset="img/theme/listening_to_heartbeat.jpg 1x, img/theme/listening_to_heartbeat@2x.jpg 2x"/>
                <img class="card-img-top" v-lazy="'img/theme/homelistening_to_heartbeat_visit.jpg'" alt="Home health services checking heartbeat" title="Checking heartbeat - HealthView" />
              </picture>
							<blockquote class="card-blockquote">
								<svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 583 95" class="svg-bg">
									<polygon points="0,52 583,95 0,95" class="fill-success"></polygon>
									<polygon points="0,42 583,95 683,0 0,95" opacity=".2" class="fill-success"></polygon>
								</svg>
								<ul class="text-white h5">
									<li class="pb-2">Skilled nursing visits</li>
									<li class="pb-2">Medication management</li>
									<li class="pb-2">Medical supplies</li>
									<li class="pb-2">Rehabilitation therapies including physical, speech, and occupational therapy</li>
									<li class="pb-2">Pain management</li>
									<li class="pb-2">Disease management</li>
									<li class="pb-2">Wound care</li>
								</ul>
							</blockquote>
						</div>
					</div>
				</div>
			</div>
		</section>
</template>

<script>
  export default {
    name: 'health_view_services'
  }
</script>
