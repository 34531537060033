<template>
		<section class="section" id="homehealth">
			<div class="container">
				<div class="row row-grid align-items-center">
					<div class="col-xs-12 col-md-5">
						<div class="card bg-info shadow border-0">
							<!-- <img v-lazy="'img/theme/home_visit.jpg'" class="card-img-top"> -->
							<picture class="card-img-top">
                <source srcset="img/theme/home_visit.jpg 1x, img/theme/home_visit@2x.jpg 2x"/>
                <img class="card-img-top" v-lazy="'img/theme/home_visit.jpg'" alt="Coming for a home health visit" title="Home Health Visit - HealthView" />
              </picture>
							<blockquote class="card-blockquote">
								<svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 583 95" class="svg-bg">
									<polygon points="0,52 583,95 0,95" class="fill-info"></polygon>
									<polygon points="0,42 583,95 683,0 0,95" opacity=".2" class="fill-info"></polygon>
								</svg>
								<p class="lead text-white">We are committed to providing high quality, comprehensive health care essential in the rehabilitation and recovery of our patients.</p>
							</blockquote>
						</div>
					</div>
					<div class="col-md-7">
						<div class="pl-md-5">
							<h2 class="display-2 font-weight-bold">Home Health</h2>
							<p class="lead">The goal of HealthView Home Health is to help our patients live independently and safely in their own home.</p>
							<p>Whether you or your loved one has had surgery and needs a plan of care that includes physical therapy and skilled nursing visits, or have a health condition that needs monitoring, HealthView Home Health offers a variety of services that are designed to help.</p>
							<div class="text-center">
								<router-link to="/contact" class="btn btn-info mt-3">Contact us to learn more. <icon name="fas fa-envelope"></icon>
								</router-link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
</template>
  
<script>
  export default {
    name: 'home_health_overview',
  }
</script>
