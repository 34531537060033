<template>
	<section class="section section-shaped my-4 overflow-hidden" id="rehabilitation_services">
		<!-- <div class="shape shape-style-1 bg-gradient-lighter shape-skew"></div> -->
		<div class="container">
			<div class="row row-grid">
				<div class="col-xs-12 col-md-5">
					<div class="card bg-info shadow border-0">
						<!-- <img v-lazy="'img/theme/chair_with_blanket.jpg'" class="card-img-top"> -->
						<picture class="card-img-top">
							<source srcset="img/theme/walker_assisted.jpg 1x, img/theme/walker_assisted@2x.jpg 2x" />
							<img class="card-img-top" v-lazy="'img/theme/walker_assisted.jpg'" alt="Assisted with using a walker" title="Assisting with using a walker - HealthView" />
						</picture>
						<blockquote class="card-blockquote">
							<svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 583 95" class="svg-bg">
								<polygon points="0,52 583,95 0,95" class="fill-info"></polygon>
								<polygon points="0,42 583,95 683,0 0,95" opacity=".2" class="fill-info"></polygon>
							</svg>
							<p class="lead text-white">Rehabilitation or skilled therapy services help you recover from an illness or surgery.</p>
						</blockquote>
					</div>
				</div>
				<div class="col-md-7">
					<div class="pl-md-5">
						<h2 class="mb-3">Rehabilitation Services &amp; Therapies</h2>
						<h3>Occupational Therapy</h3>
						<p>HealthView occupational therapists help you regain independence and mobility through customized plans of care
						with goals centered on daily living skills such as bathing, grooming, dressing and using the restroom.</p>
						<ul>
							<li>Customized treatment programs to increase your ability to perform daily activities</li>
							<li>Comprehensive home evaluations for adapting to your situation</li>
							<li>Assistive equipment recommendations and training on use</li>
							<li>Guidance for family members, caregivers and home health aides</li>
							<li>Fall assessments and recommendations</li>
						</ul>
						<h3>Physical Therapy</h3>
						<p>Our physical therapists work to improve and restore your mobility and muscle strength. These services are used to
						assist with recovery from fall injuries, joint replacement surgeries, and stroke-related disabilities. Plans of
						care may include:</p>
						<ul>
							<li>Balance coordination activities</li>
							<li>Home safety</li>
							<li>Fall prevention</li>
							<li>Pain management</li>
							<li>Home exercises to improve endurance, designed specifically for you</li>
							<li>Safe and appropriate use of adaptive equipment</li>
						</ul>
						<h3>Speech Therapy</h3>
						<p>
							HealthView speech therapists work with your primary care physician to create a treatment plan customized to
							increase your independence and improve your quality of life. This plan is designed to restore communication
							skills and abilities, such as eating and swallowing, all from the comfort of home. Plans of care may include:
						</p>
						<ul>
							<li>Evaluation of current abilities for swallowing, communication skills and cognitive loss</li>
							<li>Improve communication skills to positively impact safety and quality of life</li>
							<li>Enhance attention, memory, coping strategies and problem solving</li>
						</ul>
						<div class="text-center">
							<router-link to="/contact" class="btn btn-info mt-3">Contact us to learn more. <icon name="fas fa-envelope"></icon>
							</router-link>
						</div>
					</div>
				</div>

			</div>
		</div>
	</section>
</template>

<script>
	export default {
		name: 'rehabilitation_services'
	}
</script>