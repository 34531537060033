<template>
	<div>
		<HeroSmall :page_title="page_title" :leadline="leadline" />
		<!-- Home Health Overview -->
		<HomeHealthOverview />
		<!-- HealthView Services -->
		<HealthViewServices />
		<!-- Rehabilitation Services -->
		<RehabilitationServices />
				<!-- Emergency After Hours -->
		<section class="section section-shaped mt-4 mb-lg overflow-hidden" id="skilled_nurses">
			<div class="shape shape-style-1 bg-gradient-lighter shape-skew"></div>
			<div class="container">
				<div class="row justify-content-center text-center mb-lg">
					<div class="col-lg-8">
						<h2 class="display-3">After-Hours Emergency</h2>
						<p class="lead text-muted">HealthView Home Health skilled nurses are always on
							call, 24 hours a day, seven days a week to ensure quality, continuous care.</p>
						<h3>
							If you are experiencing a medical emergency, please contact <strong>HealthView Home Health</strong> at<br/>
							<a href="tel:1-252-462-2687" class="display-2">(252) 462-2687</a>
						</h3>
					</div>
				</div>
			</div>
		</section>
		<section class="section">
          <hr>
          <div class="row justify-content-center">
            <div class="col-lg-5">
                <card shadow class="shadow-lg--hover mt-4">
                  <p>Are you committed to providing high quality, comprehensive health care? </p>
                  <router-link class=" btn btn-info btn-block" to="/employment">Join Our Team</router-link>
                </card>
            </div>
          </div>
        </section>
	</div>
</template>
<script>
	const TIMEOUT = 1
	import HeroSmall from '../views/components/HeroSmall.vue'
	import HomeHealthOverview from '../views/services/HomeHealthOverview.vue'
	import HealthViewServices from '../views/services/HealthViewServices.vue'
	import RehabilitationServices from '../views/services/RehabilitationServices.vue'
	export default {
		name: 'about',
		metaInfo: {
			title: 'Services',
			meta: [
				{
					'description': 'HealthView Home Health & Hospice provides medical care provided at home where a patient can feel more comfortable and secure.'
				}
			]
		},
		components: {
			HeroSmall,
			HomeHealthOverview,
			HealthViewServices,
			RehabilitationServices,
		},
		data: () => ({
			page_title: 'Services',
			leadline: 'HealthView Home Health &amp; Hospice',
		}),
		mounted() {
			if (this.$route.hash) {
				setTimeout(() => this.scrollTo(this.$route.hash), TIMEOUT)
			}
		},
		methods: {
			scrollTo(hashtag) {
				setTimeout(() => {
					location.href = hashtag
				}, TIMEOUT)
			}
		}
	};
</script>
<style>
</style>